import { LicenseInfo } from '@mui/x-license'
import { DataGridPremium, GridToolbar, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium'
import * as React from 'react'
import { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Alert, Box, Snackbar } from '../papertrail-styleguide'

const key = '4ab51608d9b6481040ba4e0d0eee1111Tz03NjQ5OCxFPTE3Mjg3MzgzMjcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'

LicenseInfo.setLicenseKey(key)

type Props = {
  loading: boolean
  meta: any
  initialData: any[]
  columns: any[]
  filters: any
  check: boolean
  updatedRowValue?: (updatedRow, originalRow) => void
  newRow?: (data) => void
  rowsToDelete?: (data) => void
  updatedRowSelection?: (rows) => void
  pinnedColumns: any
  handleProcessRowUpdate?: (updatedRow: any, originalRow: any) => any
  setPaginationModel: any
  undoLastChange?: any
}

const DataGrid: React.FC<Props> = (props: Props) => {
  const {
    initialData,
    columns,
    filters,
    check,
    updatedRowValue,
    newRow,
    rowsToDelete,
    loading,
    pinnedColumns,
    updatedRowSelection,
    meta,
    setPaginationModel,
    undoLastChange
  } = props
  const apiRef = useGridApiRef()
  const [data, setData] = useState(initialData)
  const newRowIdsRef = useRef(new Set())
  const rowCountRef = useRef(meta.meta.pagination.total)
  const pagRef = useRef(meta.pagination)

  const updatedColumns = useMemo(() => {
    return columns ? columns.map((column) => ({ ...column, editable: true })) : []
  }, [columns])
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarMsg, setSnackbarMsg] = useState<string>(null)

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['commodity']
      },
      sorting: {
        sortModel: [{ field: '__row_group_by_columns_group__', sort: 'asc' }]
      },
      aggregation: {
        model: {
          quantity: 'sum'
        }
      }
    }
  })

  useEffect(() => {
    apiRef.current.setFilterModel({
      items: filters ? filters : []
    })
  }, [filters])

  useEffect(() => {
    if (meta.pagination) {
      rowCountRef.current = meta.meta.pagination.total
      pagRef.current = meta.pagination
    }
  }, [meta])

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  useEffect(() => {
    if (undoLastChange && undoLastChange.id) {
      const newData = Array.from(apiRef.current.getRowModels().values())
      const index = newData.findIndex((item) => item.id === undoLastChange.id)
      newData[index] = undoLastChange
      apiRef.current.setRows(newData)
      setData(newData)
    }
  }, [undoLastChange])

  const deleteRows = (event) => {
    const selectedRowsArr = Array.from(apiRef.current.getSelectedRows().keys())
    if (selectedRowsArr && selectedRowsArr.length > 0) {
      const currentData = Array.from(apiRef.current.getRowModels().values())
      const filteredArray = currentData.filter((item) => !selectedRowsArr.includes(item.id))
      apiRef.current.setRows(filteredArray)
      setData(filteredArray)
      rowsToDelete(selectedRowsArr)
      const rowCount = apiRef.current.getRowsCount()
      apiRef.current.setRowCount(rowCount - selectedRowsArr.length)
      rowCountRef.current = rowCount - selectedRowsArr.length
      selectedRowsArr.forEach((id) => newRowIdsRef.current.delete(id))
    }
  }

  const getNextId = () => {
    rowCountRef.current += 1
    return rowCountRef.current
  }

  const createEmptyRow = () => {
    const emptyRow = { id: getNextId() }
    columns.forEach((column) => {
      if (
        column.headerName === 'name' ||
        column.headerName === 'folder' ||
        column.headerName === 'frequency' ||
        column.headerName === 'state'
      ) {
        emptyRow[column.field] = 'value required'
      } else if (column.headerName !== 'id') {
        emptyRow[column.field] = null
      }
    })
    return emptyRow
  }

  const debounce = (func, wait) => {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  const addRow = () => {
    const row = createEmptyRow()
    if (!newRowIdsRef.current.has(row.id)) {
      const currentData = Array.from(apiRef.current.getRowModels().values())
      const newData = [...currentData, row]
      apiRef.current.setRows(newData)
      setData(newData)
      newRow(row)
      newRowIdsRef.current.add(row.id)
      apiRef.current.setRowCount(rowCountRef.current)
    }
  }

  const debouncedAddRow = useCallback(debounce(addRow, 300), [apiRef, newRow, columns])

  useEffect(() => {
    document.addEventListener('deleteRows', deleteRows)
    document.addEventListener('addRow', debouncedAddRow)
    return () => {
      document.removeEventListener('deleteRows', deleteRows)
      document.removeEventListener('addRow', debouncedAddRow)
    }
  }, [debouncedAddRow])

  const handleProcessRowUpdate = (updatedRow, originalRow) => {
    if (!updatedRow || !updatedRow.id) {
      return
    }
    try {
      if (updatedRow) {
        updatedRowValue(updatedRow, originalRow)
      }
      return updatedRow
    } catch (error) {
      console.log(error, 'error')
    }
  }
  const copyPasteMessage = (msg: string) => {
    setSnackbarMsg(msg)
    setShowSnackbar(true)
  }

  const handlePaginationChange = (paginationModel) => {
    setPaginationModel(paginationModel)
    pagRef.current = paginationModel
  }

  if (data && meta && meta.pagination) {
    return (
      <Box>
        <DataGridPremium
          rows={data}
          loading={loading}
          cellSelection
          columns={updatedColumns}
          apiRef={apiRef}
          disableRowSelectionOnClick
          onRowSelectionModelChange={(rows) => {
            updatedRowSelection(rows)
          }}
          initialState={initialState}
          checkboxSelection={check}
          rowHeight={25}
          slots={{ toolbar: GridToolbar }}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(err) => console.log(err, 'err')}
          ignoreValueFormatterDuringExport
          columnVisibilityModel={{
            product_id: false
          }}
          pinnedColumns={pinnedColumns}
          pagination
          paginationMode="server"
          paginationModel={pagRef.current}
          onPaginationModelChange={handlePaginationChange}
          pageSizeOptions={[25, 50, 100]}
          rowCount={rowCountRef.current}
          onClipboardPasteEnd={() => {
            copyPasteMessage('pasted')
          }}
          onClipboardCopy={(data) => {
            copyPasteMessage('copied')
          }}
          autoHeight
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setShowSnackbar(false)
            setSnackbarMsg(null)
          }}>
          <Alert severity="success">You have successfully {snackbarMsg} values.</Alert>
        </Snackbar>
      </Box>
    )
  } else {
    return null
  }
}

export default DataGrid
